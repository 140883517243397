import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { MAIN_MODULE_ROUTES } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
class NotAuthorizedGuardService {
  constructor(private authService: AuthenticationService,
              private router: Router) { }

  canActivate(): boolean {
    if (!this.authService.hasToken()) {
      return true;
    }

    this.router.navigateByUrl(`/${MAIN_MODULE_ROUTES.dashboard}`);
    return false;
  }
}

export const notAuthorizedGuard: CanActivateFn = () => {
  return inject(NotAuthorizedGuardService).canActivate();
};
