import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { PERM_GROUPS } from '@shared/constants';
import { PERMISSIONS } from '@shared/enums';
import { CurrentUser } from '@shared/models';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class AuthorizedGuardService {
  constructor(private readonly authService: AuthenticationService,
              private readonly nps: NgxPermissionsService,
              private readonly r: Router,
              private readonly router: Router) { }

  canActivate(): Observable<boolean> | boolean {
    if (this.authService.hasToken()) {

      const userRole = Object.keys(this.nps.getPermissions());

      if (userRole.length === 0) {
        return this.authService.getMe().pipe(map((user) => {
          this.buildPermissions(user);
          return true;
        }),
          catchError(() => {
            return of(true);
          }));
      }

      return true;
    }

    this.router.navigateByUrl(`/login`);
    return false;
  }

  buildPermissions(user: CurrentUser): void {
    const groups = user.principal?.claims?.groups || [];
    const isShlonakUser = groups.filter(group => group.includes('/Shlonak'))?.length;
    const isBackOfficeUser = groups.filter(group => group.includes('/Back Office'))?.length;
    const isAdmin = groups.filter(group => group.includes('/Admin'))?.length;
    const isSupervisor = groups.filter(group => group.includes('/Supervisors'))?.length;

    if (isShlonakUser && isBackOfficeUser) {
      this.nps.addPermission(PERM_GROUPS.SHLONAK);
    } else {
      if (!user?.customerEnabled) {
        this.r.navigate(['/unauthorized']);
        return;
      }

      if (isAdmin && user?.customerLicenseActive) {
        this.nps.addPermission([ PERMISSIONS.BACK_OFFICE_USER_ADMIN ]);
      }

      if (isSupervisor && user?.customerLicenseActive) {
        this.nps.addPermission([ PERMISSIONS.BACK_OFFICE_USER_SUPERVISOR ]);
      }

      if (!user?.customerLicenseActive) {
        this.nps.addPermission([ PERMISSIONS.BACK_OFFICE_USER_VIEW ]);
      }
    }
  }
}

export const authorizedGuard: CanActivateFn = () => {
  return inject(AuthorizedGuardService).canActivate();
};
