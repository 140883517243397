import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private modal: NzModalService,
              private notification: NzNotificationService) { }

  /**
   * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
   * @see HttpInterceptor
   * @param req the outgoing HTTP request
   * @param next a HTTP request handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = req.headers.has('skip');

    if (skipIntercept) {
      req = req.clone({
        headers: req.headers.delete('skip')
      });
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((response) => {

        if (response instanceof HttpErrorResponse) {
          this.handleError(response);
        }

        return throwError(() => response);
      })
    );
  }

  handleError(response: HttpErrorResponse): void {
    switch (response.status) {
      case 400:
      case 404:
      case 500: {
        if (!response.error || (response.error && response.error.error !== 'invalid_grant')) {
          this.notification.create(
            'error',
            'Failed',
            response.error && response.error.message || response.message
          );
        }
        break;
      }
      case 502:
      case 503:
      case 504:
        this.notification.create(
          'error',
          'Error',
          'Unable to establish a connection to the Server.'
        );
    }
  }
}
