import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OauthToken } from '@shared/models';
import { BehaviorSubject, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  constructor(private as: AuthenticationService,
              private r: Router) { }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private applyCredentials = (request: HttpRequest<any>) => {
    const token = this.as.getAccessToken();
    const API_URL = ['/api/'];

    if (token && API_URL.some(url => request.url.includes(url))) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.headers.get('exclude')) {
      return next.handle(request);
    } else {
      request = this.applyCredentials(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {

          // Refresh token first before invalidating session
          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject.pipe(
              filter((token) => token !== null),
              take(1),
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              switchMap((token) => {
                if (token) {
                  return next.handle(this.applyCredentials(request));
                }
                this.refreshTokenInProgress = false;
              }),
              untilDestroyed(this));
          } else {
            this.refreshTokenInProgress = true;
            this.refreshTokenSubject.next(null);
            this.as.removeToken();

            return this.as.refreshToken(this.as.getRefreshToken() || '')
              .pipe(
                switchMap((oauth: OauthToken) => {
                  const newToken = oauth!.access_token;
                  if (newToken) {
                    this.refreshTokenSubject.next(newToken);
                    return next.handle(this.applyCredentials(request));
                  }
                  this.goToLoginPage();
                  return throwError(error);
                }),
                untilDestroyed(this),
                catchError((e) => {
                  this.goToLoginPage();
                  return throwError(e);
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                })
              );
          }
        }

        if (error?.error?.error === 'invalid_grant') {
          this.r.navigateByUrl('/login');
        }

        // return all others errors
        return throwError(error);

      })) as any;
  }

  private goToLoginPage(): void {
    this.r.navigateByUrl('/login');
  }

}
