import { ErrorHandler, Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk \d+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      if (confirm("New version available. Load New Version?")) {
        window.location.reload();
      }
    } else {
      console.error(error);
    }
  }
}
