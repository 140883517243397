import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconsProviderModule } from '@shared/modules/icons-provider.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MenuComponent } from './menu/menu.component';
import { SecureLayoutComponent } from './secure-layout.component';


@NgModule({
  declarations: [
    SecureLayoutComponent,
    MenuComponent,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    IconsProviderModule,
    NgxPermissionsModule,
    RouterModule,
    NzButtonModule,
    NzBreadCrumbModule,
    NzDropDownModule,
    NzSpaceModule,
    NzLayoutModule
  ]
})
export class LayoutModule { }
