import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ACTIONS, MAIN_MODULE_ROUTES, MESSAGES } from '@shared/constants';
import { User } from '@shared/models';
import { UserService } from '@shared/services/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { finalize, Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html',
  styleUrl: './secure-layout.component.scss'
})
export class SecureLayoutComponent {

  isCollapsed = false;
  profile$: Observable<User>;

  ROUTE = MAIN_MODULE_ROUTES;
  currentUser: string;

  constructor(private auth: AuthenticationService,
              private us: UserService,
              private ms: NzModalService,
              private r: Router) {
                this.observeUser();
  }

  observeUser(): void {
    this.auth.getUpdatedUser$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.getName());
  }

  getName(): void {
    this.us.getMe()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.currentUser = `${res.firstName} ${res.lastName}`;
      });
  }

  confirmLogout(): void {
    this.ms.confirm({
      nzTitle: MESSAGES.confirmLogout,
      nzOkText: ACTIONS.yes,
      nzCancelText: ACTIONS.no,
      nzAutofocus: 'ok',
      nzOnOk: () => this.logout()
    });
  }

  private logout(): void {
    this.auth.logout()
      .pipe(finalize(() => this.r.navigateByUrl('/login'))).subscribe();
  }

  showHide(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
  }

}
