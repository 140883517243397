import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAIN_MODULE_ROUTES, MENU, PERM_GROUPS } from '@shared/constants';
import { Menu } from '@shared/models';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent {

  @Input() isCollapsed!: boolean;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  menuItems!: Menu[];
  // open current submenu only
  activeRoute!: string;
  activeChildRoute: string | null;

  constructor() {
    this.getMenuItems();
  }

  private getMenuItems(): void {
    this.menuItems = [
      {
        label: MENU.customerAccounts,
        route: MAIN_MODULE_ROUTES.admin,
        icon: 'users',
        permissions: PERM_GROUPS.SHLONAK,
        children: [],
      },
      {
        label: MENU.dashboard,
        route: MAIN_MODULE_ROUTES.dashboard,
        icon: 'dashboard',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.issues,
        route: MAIN_MODULE_ROUTES.issues,
        icon: 'issues',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.assets,
        route: MAIN_MODULE_ROUTES.assets,
        icon: 'assets',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.components,
        route: MAIN_MODULE_ROUTES.components,
        icon: 'components',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.inspections,
        route: MAIN_MODULE_ROUTES.inspections,
        icon: 'inspections',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.checklists,
        route: MAIN_MODULE_ROUTES.checklists,
        icon: 'checklists',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
      {
        label: MENU.users,
        route: MAIN_MODULE_ROUTES.users,
        icon: 'users',
        permissions: PERM_GROUPS.BACK_OFFICE_ADMIN,
        children: [],
      },
      {
        label: MENU.settings,
        route: MAIN_MODULE_ROUTES.settings,
        icon: 'settings',
        permissions: PERM_GROUPS.BACK_OFFICE,
        children: [],
      },
    ];
  }

}

