import { ErrorHandler, Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (error?.message?.includes('Failed to fetch dynamically imported module')) {
      if (confirm("New version available. Load New Version?")) {
        window.location.reload();
      }
    } else {
      console.error(error);
    }
  }
}
