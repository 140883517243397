<nz-layout class="layout">
  <nz-sider nzTheme="dark" [nzWidth]="256" nzBreakpoint="xl" [(nzCollapsed)]="isCollapsed">
    <div class="logo">
      <img
        class="img-fluid c-pointer"
        [src]="isCollapsed ? 'assets/brand/shlonak-icon.png' : 'assets/brand/shlonak-light.png'"
        routerLink="/"
        alt="" />
    </div>
    <div class="scroll-container">
      <app-menu [isCollapsed]="isCollapsed"></app-menu>
    </div>
    <div class="user-btn-container">
      <button nz-button nzType="link"
              nz-dropdown [nzDropdownMenu]="menu" nzBlock
              [nzTrigger]="'click'" nzPlacement="topCenter">
        <div class="d-flex align-items-center text-truncate justify-content-xl-start justify-content-center">
          <span class="user-icon rounded-circle border p-2" nz-icon nzType="user" nzTheme="outline"></span>
          <span class="font-weight-bold fs-6 user-name ms-3 text-truncate d-xl-block d-none">{{ (profile$ | async)?.fullName || '&nbsp;' }}</span>
        </div>
      </button>
    </div>
  </nz-sider>
  <nz-layout class="right-layout" [ngClass]="{'right-layout-closed': isCollapsed}">
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>


<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu nzSize="large" class="py-2">
    <li nz-menu-item [routerLink]="['/', ROUTE.profile]" class="py-2 px-4">
      <span class="pe-2" nz-icon nzType="user" nzTheme="outline"></span> Profile
    </li>
    <li nz-menu-item (click)="confirmLogout()" class="py-2 px-4">
      <span class="pe-2" nz-icon nzType="logout" nzTheme="outline"></span> Logout
    </li>
  </ul>
</nz-dropdown-menu>
